import { getAddressBooks } from '../clients/addressBookStoreClient'
import { useAlertPopup } from '../context/useAlertPopup'
import { IdentityContextValue, useIdentity, useUserContext, useLogger } from '@vp/ubik-context'
import { useLocalization } from '../context/useLocalizations'
import { IMailingListStatusSummary } from '../models/MailingListSummary'
import { getCountryFromLocale } from '../utils/localeUtils'
import {
  getMailingListSummary,
  deleteMailingList,
  downloadMailingList,
  renameMailingList,
} from '../clients/customerAddressBookClient'
import { useState } from 'react'

export interface ServiceClients {
  getAddressBookId(authToken: string): Promise<string>
  getMailingListSummaryData(
    addressBookId: string,
  ): Promise<IMailingListStatusSummary>
  getBookStoreAuthToken(): Promise<string>
  deleteMailingListItem(itemId: string): Promise<void>
  downloadMailingListItem(itemId: string): Promise<void>
  renameMailingListItem(itemId: string, newName: string): Promise<void>
  isLoading: boolean
}

const useServiceClients = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { identity, auth } = useIdentity() as IdentityContextValue
  const { locale } = useUserContext()
  const country = getCountryFromLocale(locale)
  const { t } = useLocalization()

  const somethingWentWrong = t('somethingWentWrong')
  const errorDownloadingMailingList = t('errorDownloadingMailingList')

  const { showError, showMessage } = useAlertPopup()
  const logger = useLogger()

  const getAddressBookId = async (): Promise<string | undefined> => {
    if (identity != null) {
      const { authorizationHeader } = identity
      const canonicalId = auth?.getCanonicalId()
      try {
        setIsLoading(true)
        const addressBooks = await getAddressBooks({
          country,
          authorizationHeader,
        })
        setIsLoading(false)
        if (addressBooks.length > 0) {
          return addressBooks[0].id
        }
      } catch (error) {
        setIsLoading(false)
        showError(somethingWentWrong)
        logger.error('Error while getting the addressBookId', {
          country,
          canonicalId,
          error,
        })
      }
    }
  }

  const getMailingListSummaryData = async (
    addressBookId: string
  ): Promise<IMailingListStatusSummary> => {
    let summaryData: IMailingListStatusSummary = []
    if (identity != null) {
      const { authorizationHeader } = identity
      const canonicalId = auth?.getCanonicalId()
      try {
        setIsLoading(true)
        summaryData =
          (await getMailingListSummary({
            locale,
            shopperId: canonicalId,
            authorizationHeader,
            addressBookId,
          })) ?? []
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        showError(somethingWentWrong)
        logger.error('Error while getting the mailing list summary data', {
          locale,
          addressBookId,
          canonicalId,
          error,
        })
      }
    }
    return summaryData
  }

  const deleteMailingListItem = async (
    itemId: string,
    currentMailingListName: string
  ): Promise<void> => {
    if (identity != null) {
      const { authorizationHeader } = identity
      const canonicalId = auth?.getCanonicalId()
      try {
        setIsLoading(true)
        await deleteMailingList({
          mailingListId: itemId,
          shopperId: canonicalId,
          authorizationHeader,
        })
        setIsLoading(false)
        showMessage(
          t('successfullyDeleted', {
            mailingListName: currentMailingListName,
          })
        )
      } catch (error) {
        setIsLoading(false)
        showError(somethingWentWrong)
        logger.error('Error while deleting the mailing list', {
          locale,
          itemId,
          currentMailingListName,
          canonicalId,
          error,
        })
        throw error
      }
    }
  }

  const downloadMailingListItem = async (
    itemId: string,
    currentMailingListName: string
  ): Promise<void> => {
    if (identity != null) {
      const { authorizationHeader } = identity
      const canonicalId = auth?.getCanonicalId()
      try {
        setIsLoading(true)
        await downloadMailingList({
          mailingListId: itemId,
          shopperId: canonicalId,
          authorizationHeader,
          locale,
        })
        setIsLoading(false)
        showMessage(
          t('successfullyDownloaded', {
            mailingListName: currentMailingListName,
          })
        )
      } catch (error) {
        setIsLoading(false)
        showError(errorDownloadingMailingList)
        logger.error('Error while downloading the mailing list', {
          locale,
          itemId,
          currentMailingListName,
          canonicalId,
          error,
        })
      }
    }
  }

  const renameMailingListItem = async (params: {
    itemId: string
    newName: string
    currentMailingListName: string
  }): Promise<void> => {
    const { itemId, newName, currentMailingListName } = params
    if (identity != null) {
      const { authorizationHeader } = identity
      const canonicalId = auth?.getCanonicalId()
      try {
        setIsLoading(true)
        await renameMailingList({
          mailingListId: itemId,
          shopperId: canonicalId,
          authorizationHeader,
          newName,
        })
        setIsLoading(false)
        showMessage(
          t('successfullyRenamed', {
            mailingListNameOldName: currentMailingListName,
            mailingListName: newName,
          })
        )
      } catch (error) {
        setIsLoading(false)
        showError(somethingWentWrong)
        logger.error('Error while renaming the mailing list', {
          locale,
          itemId,
          currentMailingListName,
          newName,
          canonicalId,
          error,
        })
        throw error
      }
    }
  }

  return {
    getAddressBookId,
    getMailingListSummaryData,
    deleteMailingListItem,
    isLoading,
    downloadMailingListItem,
    renameMailingListItem,
  }
}

export default useServiceClients
