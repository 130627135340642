import React from 'react'
import { Button, Link } from '@vp/swan'
import { useLocalization } from '../context/useLocalizations'
import { useProductUrl } from '../context/useProductUrl'

const MailingServicesPostcardCTA = () => {
  const { t } = useLocalization()
  const productUrl = useProductUrl()
  return (

    <Button
      component='a'
      skin='primary'
      data-section='Start Mailing Services Postcard Button'
      data-position={0}
      data-translation='Button Clicked:Start Mailing Services Postcard Button'
      render={props => (
        <Link to={productUrl} className={props.className}>
          {props.children}
        </Link>
      )}
    >
      {t('startProductFlow')}
    </Button>

  )
}

export default MailingServicesPostcardCTA
