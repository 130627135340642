import React, { useState } from 'react'
import { Button, Spinner } from '@vp/swan'
import { ActionClickPayload } from '../ActionButtons'
import { SummaryItemAction } from '../../models/MailingListSummary'
import { useLocalization } from '../../context/useLocalizations'

interface ActionsProps {
  id: string
  onActionClick(event: ActionClickPayload): void
  mailingListName: string
  index: number
}

const Download: React.FC<ActionsProps> = ({
  id,
  onActionClick,
  mailingListName,
  index,
}) => {
  const { t } = useLocalization()
  const { downloadButtonTitle, downloadingInProgress } = {
    downloadButtonTitle: t('downloadButtonTitle'),
    downloadingInProgress: t('downloadingInProgress'),
  }
  const [downloadOnProgress, setDownloadOnProgress] = useState(false)

  const onDownloadHandler = async (performDownload: boolean): Promise<void> => {
    if (performDownload === true) {
      setDownloadOnProgress(true)
      try {
        await onActionClick({
          type: SummaryItemAction.download,
          itemId: id,
          currentName: mailingListName,
        })
      } catch {
        // Nothing - we need to avoid blowing up in order to set the delete process to false
      }

      setDownloadOnProgress(false)
    }
  }

  return (
    <Button
      data-testid='download-table-button'
      skin='secondary'
      size='mini'
      mr='4'
      px='5'
      key={id}
      data-section='Mailing List Download Button'
      data-position={(index + 1).toString()}
      data-translation='Button Clicked:Mailing List Download Button'
      onClick={() => onDownloadHandler(true)}
    >
      {downloadOnProgress && <Spinner size='tiny' accessibleText={downloadingInProgress} />}
      {downloadButtonTitle}
    </Button>
  )
}

export default Download
