import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  FlexBox,
} from '@vp/swan'
import React from 'react'
import { useUserContext } from '@vp/ubik-context'
import { useLocalization } from '../../context/useLocalizations'
import { useResponsiveState } from '../../context/useResponsiveProvider'
import useServiceClients from '../../hooks/useServiceClients'
import {
  IMailingListStatusSummary,
  SummaryItemAction,
} from '../../models/MailingListSummary'
import { buildCaptionButtonFromAction } from '../../title/buildButtonTitle'
import { buildSummaryDateDataTitle } from '../../title/buildSummaryDateDataTitle'
import ActionButtons, { ActionClickPayload } from '../ActionButtons'
import MailingListTitleCell from './MailingListTitleCell'
import { trackEvent } from '../../Analytics/tracking'
import {
  TrackingEventLabel,
  TrackingAction,
  TrackingCategory,
} from '../../constants'

interface MailingListsTableProps {
  data: IMailingListStatusSummary
  onDataChange(newData: IMailingListStatusSummary): void
}

const MailingListsTable: React.FC<MailingListsTableProps> = ({
  data,
  onDataChange,
}) => {
  const { t } = useLocalization()
  const listTitle = t('listTitle')
  const status = t('status')
  const entries = t('entries')
  const actions = t('actions')
  const purchased = t('purchased')
  const uploaded = t('uploaded')
  const archive = t('archive')
  const deleteTitle = t('deleteTitle')
  const migrated = t('migrated')
  const downloadButtonTitle = t('downloadButtonTitle')
  const renameButtonTitle = t('renameButtonTitle')
  const deleteButtonTitle = t('deleteButtonTitle')
  const mailingLists = t('mailingLists')

  const {
    deleteMailingListItem,
    downloadMailingListItem,
    renameMailingListItem,
  } = useServiceClients()
  const { locale } = useUserContext()
  const { isMobile, isSmallScreen } = useResponsiveState()

  const onActionClickHandler = async (payload: ActionClickPayload) => {
    switch (payload.type) {
      case SummaryItemAction.delete:
        trackEvent({
          trackingData: {
            label: TrackingEventLabel.DeletePopup,
            action: TrackingAction.PopupClicked,
          },
          category: TrackingCategory.Popup,
        })
        await deleteMailingListItem(payload.itemId, payload.currentName)
        onDataChange(data.filter(item => item.mailingListId !== payload.itemId))
        break
      case SummaryItemAction.download:
        trackEvent({
          trackingData: {
            label: TrackingEventLabel.Download,
            action: TrackingAction.DownloadClicked,
          },
          category: TrackingCategory.Page,
        })
        await downloadMailingListItem(payload.itemId, payload.currentName)
        break
      case SummaryItemAction.rename: {
        trackEvent({
          trackingData: {
            label: TrackingEventLabel.RenamePopup,
            action: TrackingAction.PopupClicked,
          },
          category: TrackingCategory.Popup,
        })
        await renameMailingListItem({
          itemId: payload.itemId,
          newName: payload.newName as string,
          currentMailingListName: payload.currentName,
        })
        const renamedItem = data.find(
          item => item.mailingListId === payload.itemId
        )
        if (renamedItem != null) {
          renamedItem.name = payload.newName || ''
          onDataChange([...data])
        }

        break
      }
      default:
        break
    }
  }

  const actionButtonCaptions = {
    delete: deleteButtonTitle,
    rename: renameButtonTitle,
    download: downloadButtonTitle,
  }

  const existingMailingListNames = data.map(mlItem => mlItem.name)
  const flexDirection = isMobile || isSmallScreen ? 'column' : 'row'

  return (
    <Table my='6' aria-labelledby={mailingLists}>
      <TableHead bgc='strong'>
        <TableRow>
          <TableCell textAlign='left' py='5' px='5'>
            {listTitle}
          </TableCell>
          <TableCell textAlign='left'>{status}</TableCell>
          <TableCell textAlign='left'>{entries}</TableCell>
          <TableCell textAlign='right' px='7'>
            {actions}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((mailingListSummaryItem, index) => {
          const titleProps = {
            translations: {
              purchased,
              uploaded,
              archive,
              deleteTitle,
              migrated,
            },
            locale,
            type: mailingListSummaryItem.type,
            createdOn: mailingListSummaryItem.createdOn,
            expiresOn: mailingListSummaryItem.expiresOn,
          }
          const dateData = buildSummaryDateDataTitle(titleProps)
          return (
            <TableRow
              bgc={index % 2 === 0 ? 'standard' : 'strong'}
              data-testid={`mailing-list-item-${index}`}
              key={mailingListSummaryItem.mailingListId}
            >
              <TableCell
                textAlign='left'
                py='5'
                px='5'
                style={{ maxWidth: '200px', overflowWrap: 'break-word' }}
              >
                <MailingListTitleCell
                  name={mailingListSummaryItem.name}
                  dateData={dateData}
                  index={index}
                />
              </TableCell>
              <TableCell textAlign='left'>
                <Typography fontSkin='body-small'>
                  {mailingListSummaryItem.localizedStatus}
                </Typography>
              </TableCell>
              <TableCell textAlign='left'>
                <Typography fontSkin='body-small'>
                  {mailingListSummaryItem.numberOfAddresses}
                </Typography>
              </TableCell>
              <TableCell textAlign='right' px='7'>
                <FlexBox
                  flexWrap='nowrap'
                  flexDirection={flexDirection}
                  justifyContent='end'
                >
                  <ActionButtons
                    index={index}
                    onActionClick={onActionClickHandler}
                    existingMailingListNames={existingMailingListNames}
                    actions={mailingListSummaryItem.actions.map(action => {
                      return {
                        action,
                        mailingListName: mailingListSummaryItem.name,
                        id: `${mailingListSummaryItem.mailingListId}`,
                        caption: buildCaptionButtonFromAction(
                          action,
                          actionButtonCaptions
                        ),
                      }
                    })}
                  />
                </FlexBox>
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default MailingListsTable
