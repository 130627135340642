import { Box, Column, FlexBox, GridContainer, Row, Typography } from '@vp/swan'
import { useEffect, useState } from 'react'
import { IdentityContextValue, useIdentity, useLogger } from '@vp/ubik-context'
import { useLocalization } from '../../context/useLocalizations'
import { useResponsiveState } from '../../context/useResponsiveProvider'
import useServiceClients from '../../hooks/useServiceClients'
import { IMailingListStatusSummary } from '../../models/MailingListSummary'
import MailingServicesPostcardCTA from '../MailingServicesPostcardCTA'
import MailingListsTable from './MailingListsTable'
import MailingListTiles from './MailingListTiles'
import NoMailingLists from './NoMailingLists'
import SignInMessage from './SignInMessage'

const MailingListSummary = () => {
  const { identity, auth } = useIdentity() as IdentityContextValue
  const [mailingListSource, setMailingListSource] = useState<
    IMailingListStatusSummary | undefined
  >(undefined)
  const { getAddressBookId, getMailingListSummaryData, isLoading } =
    useServiceClients()
  const { t } = useLocalization()
  const mailingListDescription = t('mailingListDescription')
  const { isMobile } = useResponsiveState()
  const mailingListSummaryChangeHandler = (
    newSummaryData: IMailingListStatusSummary
  ) => {
    setMailingListSource(newSummaryData)
  }
  const contentAlign = isMobile ? 'center' : 'right'

  useEffect(() => {
    async function setSummary () {
      if (identity !== undefined) {
        const addressBookId = await getAddressBookId()
        let summaryData: IMailingListStatusSummary = []
        if (addressBookId != null) {
          summaryData = await getMailingListSummaryData(addressBookId)
        }

        setMailingListSource(summaryData)
      }
    }

    setSummary()
  }, [JSON.stringify(identity)])

  const logger = useLogger()
  if (auth !== undefined && auth.isSignedIn() === false) {
    logger.info('User is not signed in', {})
    return <SignInMessage />
  }

  if (mailingListSource === undefined) {
    return (
      <>
        {isLoading && (
          <Box
            loadingShimmer
            className='mailing-list-table-placeholder'
            my='5'
          />
        )}
      </>
    )
  }

  return mailingListSource.length === 0
    ? (
      <NoMailingLists />
      )
    : (
      <>
        <GridContainer>
          <Row>
            <Column span={8}>
              <Typography className='css-fix' fontSkin='body-small'>
                {mailingListDescription}
              </Typography>
            </Column>
            <Column span={4}>
              <FlexBox
                flexWrap='nowrap'
                flexDirection='row'
                justifyContent={contentAlign}
                my={{ xs: '5', sm: '5' }}
              >
                <MailingServicesPostcardCTA data-testid='SummaryMailingServicesPostcardCtaButton' />
              </FlexBox>
            </Column>
          </Row>
        </GridContainer>
        {isMobile && (
          <MailingListTiles
            data={mailingListSource}
            onDataChange={mailingListSummaryChangeHandler}
          />
        )}
        {!isMobile && (
          <MailingListsTable
            data={mailingListSource}
            onDataChange={mailingListSummaryChangeHandler}
          />
        )}
      </>
      )
}

export default MailingListSummary
