import React, { FC, ChangeEvent, useState, useEffect } from 'react'
import {
  Button,
  ModalDialog,
  ModalDialogBody,
  ModalDialogButtons,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogFooter,
  ModalDialogHeader,
  ModalDialogNav,
  ModalDialogTitle,
  FormField,
  FormInputGroup,
  FormLabel,
  StandardForm,
  TextInput,
  FormError,
} from '@vp/swan'
import { useLocalization } from '../../context/useLocalizations'

const FILE_NAME_MAX_LENGTH = 64

interface ActionsProps {
  mailingListName: string
  existingMailingListNames: string[]
  isOpen: boolean
  onRename(performRename: boolean, newName: string): Promise<void>
  onCancel(): void
}

interface ErrorStatus {
  isValid: boolean
  errorMessage: string
}

const RenameConfirmation: FC<ActionsProps> = ({
  mailingListName,
  onRename,
  onCancel,
  existingMailingListNames,
  isOpen,
}: ActionsProps) => {
  const { t } = useLocalization()
  const {
    renameFileCTA,
    cancelButtonCaption,
    closeButtonCaption,
    renameNewName,
    renameDialogContent,
  } = {
    renameFileCTA: t('renameFileCTA'),
    cancelButtonCaption: t('cancelButtonCaption'),
    closeButtonCaption: t('closeButtonCaption'),
    renameNewName: t('renameNewName'),
    renameDialogContent: t('renameDialogContent'),
  }
  const [newName, setNewName] = useState('')
  const [userHasEnteredText, setUserHasEnteredText] = useState(false)

  useEffect(() => {
    setNewName('')
    setUserHasEnteredText(false)
  }, [isOpen])

  const onRenameConfirmationClickHandler = async (performRename: boolean) => {
    await onRename(performRename, newName)
  }

  const onNewNameHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setUserHasEnteredText(true)
    setNewName(event.target.value)
  }
  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault()
  }

  const { isValid, errorMessage } = getErrorStatus({
    currentName: newName,
    existingMailingListNames,
    userHasEnteredText,
    t
  })

  const skin = isValid ? 'standard' : 'error'

  const renameDialogTitle = t('renameFile', { mailingListName })

  return (
    <ModalDialog
      isOpen={isOpen}
      onRequestDismiss={() => onRenameConfirmationClickHandler(false)}
    >
      <ModalDialogContent aria-labelledby={renameDialogContent}>
        <ModalDialogNav>
          <ModalDialogCloseButton
            visuallyHiddenLabel={closeButtonCaption}
            data-section='Mailing List Rename Pop-Up'
            data-position='0'
            data-translation='Pop-Up Closed'
          />
        </ModalDialogNav>
        <ModalDialogHeader style={{ maxWidth: 'fit-content' }}>
          <ModalDialogTitle>{renameDialogTitle}</ModalDialogTitle>
        </ModalDialogHeader>
        <ModalDialogBody style={{ textAlign: 'left' }}>
          <StandardForm skin='standard' onSubmit={handleSubmit}>
            <FormField>
              <FormLabel>{renameNewName}</FormLabel>
              <FormInputGroup>
                <TextInput
                  aria-required='true'
                  data-testid='rename-file-text'
                  skin={skin}
                  placeholder={mailingListName}
                  maxLength={FILE_NAME_MAX_LENGTH + 1}
                  value={newName}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    onNewNameHandler(event)}
                />
                {!isValid && (
                  <FormError
                    data-testid='rename-error-message'
                    fontSkin='body-standard'
                  >
                    {errorMessage}
                  </FormError>
                )}
              </FormInputGroup>
            </FormField>
          </StandardForm>
        </ModalDialogBody>
        <ModalDialogFooter>
          <ModalDialogButtons>
            <Button
              disabled={!isValid || !userHasEnteredText}
              skin='primary'
              data-testid='rename-confirmation-button'
              data-section='Mailing List Rename Pop-Up'
              data-position='1'
              data-translation='Pop-Up Clicked:Mailing List Rename Pop-Up'
              onClick={() => onRenameConfirmationClickHandler(true)}
            >
              {renameFileCTA}
            </Button>
            <Button ml='between-actions' skin='secondary' onClick={onCancel}>
              {cancelButtonCaption}
            </Button>
          </ModalDialogButtons>
        </ModalDialogFooter>
      </ModalDialogContent>
    </ModalDialog>
  )
}

const getErrorStatus = (params: {
  currentName: string
  existingMailingListNames: string[]
  userHasEnteredText: boolean
  t: (key: string) => string
}): ErrorStatus => {
  const {
    t,
    currentName,
    existingMailingListNames,
    userHasEnteredText,
  } = params
  const {
    emptyNameErrorMessage,
    nameAlreadyExistsErrorMessage,
    nameTooLongErrorMessage,
    invalidCharactersErrorMessage,
  } = {
    emptyNameErrorMessage: t('emptyNameErrorMessage'),
    nameAlreadyExistsErrorMessage: t('nameAlreadyExistsErrorMessage'),
    nameTooLongErrorMessage: t('nameTooLongErrorMessage'),
    invalidCharactersErrorMessage: t('invalidCharactersErrorMessage'),
  }

  const currentStatus = {
    isValid: true,
    errorMessage: '',
  }

  if (userHasEnteredText && currentName === '') {
    currentStatus.isValid = false
    currentStatus.errorMessage = emptyNameErrorMessage
    return currentStatus
  }

  if (existingMailingListNames.includes(currentName)) {
    currentStatus.isValid = false
    currentStatus.errorMessage = nameAlreadyExistsErrorMessage
    return currentStatus
  }

  if (currentName.length > FILE_NAME_MAX_LENGTH) {
    currentStatus.isValid = false
    currentStatus.errorMessage = nameTooLongErrorMessage
    return currentStatus
  }

  if (currentName !== '' && !currentName.match(/^[0-9a-zA-Z ..._-]+$/)) {
    currentStatus.isValid = false
    currentStatus.errorMessage = invalidCharactersErrorMessage
    return currentStatus
  }

  return currentStatus
}

export default RenameConfirmation
