import React, { ReactNode } from 'react'

interface Props {
  children: ReactNode
  productUrl: string
}

export const ProductUrlContext = React.createContext('')

const isBrowser = typeof navigator !== 'undefined'

const ProductUrlProvider: React.FC<Props> = ({ children, productUrl }) => {
  return (
    <ProductUrlContext.Provider value={productUrl}>
      {children}
    </ProductUrlContext.Provider>
  )
}

const useProductUrl = (): string => {
  const context = React.useContext(ProductUrlContext)
  if (isBrowser && context === undefined) {
    throw new Error('useProductUrl must be used within a ProductUrlProvider')
  }
  return context
}

export { ProductUrlProvider, useProductUrl }
