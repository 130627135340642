import { MainContentStart, SWAN_STYLE_KEY_MAP, ScreenClassProvider, SiteMain } from '@vp/swan'
import { useSwanStyleKeys, useStyles } from '@vp/ubik-context'
import { AlertPopupProvider } from '../context/useAlertPopup'
import MailingListContainer from './MailingList/MailingListsContainer'

import { stylesheet } from '../styles/Global.scss'
import { ProductUrlProvider } from '../context/useProductUrl'

export interface AddressListFragmentProps {
  locale: string
  localizations: { [key: string]: any }
  mailingServicesPostcardsProductUrl: string
}

export const Fragment = (props: AddressListFragmentProps) => {
  const { mailingServicesPostcardsProductUrl } = props
  useSwanStyleKeys([
    SWAN_STYLE_KEY_MAP.core,
    SWAN_STYLE_KEY_MAP.utility,
    SWAN_STYLE_KEY_MAP.grid,
    SWAN_STYLE_KEY_MAP.toggleSwitch,
    SWAN_STYLE_KEY_MAP.standardTile,
    SWAN_STYLE_KEY_MAP.alertBox,
    SWAN_STYLE_KEY_MAP.button,
    SWAN_STYLE_KEY_MAP.accordion,
    SWAN_STYLE_KEY_MAP.modalDialog,
    SWAN_STYLE_KEY_MAP.icon,
    SWAN_STYLE_KEY_MAP.hidden,
    SWAN_STYLE_KEY_MAP.visible,
    SWAN_STYLE_KEY_MAP.selectionSet,
    SWAN_STYLE_KEY_MAP.tabs,
    SWAN_STYLE_KEY_MAP.progressiveImage,
    SWAN_STYLE_KEY_MAP.progressBar,
    SWAN_STYLE_KEY_MAP.carousel,
    SWAN_STYLE_KEY_MAP.pagination,
    SWAN_STYLE_KEY_MAP.popover,
    SWAN_STYLE_KEY_MAP.listbox,
    SWAN_STYLE_KEY_MAP.table,
    SWAN_STYLE_KEY_MAP.spinner])

  useStyles(stylesheet)

  return (
    <ScreenClassProvider>
      <AlertPopupProvider>
        <ProductUrlProvider productUrl={mailingServicesPostcardsProductUrl}>
          <SiteMain>
            <MainContentStart />
            <MailingListContainer />
          </SiteMain>
        </ProductUrlProvider>
      </AlertPopupProvider>
    </ScreenClassProvider>
  )
}
