import React, { useState } from 'react'
import { Spinner, Button } from '@vp/swan'

import { ActionClickPayload } from '../ActionButtons'
import { SummaryItemAction } from '../../models/MailingListSummary'
import RenameConfirmation from '../Modals/RenameConfirmation'
import { trackEvent } from '../../Analytics/tracking'
import {
  TrackingEventLabel,
  TrackingAction,
  TrackingCategory,
} from '../../constants'
import { useLocalization } from '../../context/useLocalizations'
interface ActionsProps {
  id: string
  mailingListName: string
  existingMailingListNames: string[]
  onActionClick(event: ActionClickPayload): Promise<void>
  index: number
}

const Rename: React.FC<ActionsProps> = ({
  id,
  mailingListName,
  onActionClick,
  existingMailingListNames,
  index,
}) => {
  const [renameOnProgress, setRenameOnProgress] = useState(false)
  const [showRenameConfirmation, setShowRenameConfirmation] = useState(false)
  const { t } = useLocalization()
  const { renameButtonTitle, renamingInProgress } = {
    renameButtonTitle: t('renameButtonTitle'),
    renamingInProgress: t('renamingInProgress'),
  }
  const onRenameHandler = async (
    performRename: boolean,
    newName: string
  ): Promise<void> => {
    setShowRenameConfirmation(false)
    if (performRename === true) {
      setRenameOnProgress(true)
      try {
        await onActionClick({
          type: SummaryItemAction.rename,
          itemId: id,
          newName,
          currentName: mailingListName,
        })
      } catch {
        // Nothing - we need to avoid blowing up in order to set the delete process to false
      }

      setRenameOnProgress(false)
    } else {
      trackEvent({
        trackingData: {
          label: TrackingEventLabel.DeletePopup,
          action: TrackingAction.PopupClosed,
        },
        category: TrackingCategory.Popup,
      })
    }
  }

  const onCancelHandler = () => {
    setShowRenameConfirmation(false)
  }

  const onRenameConfirmationHandler = async (
    e: React.MouseEvent<HTMLElement>
  ) => {
    e.preventDefault()
    setShowRenameConfirmation(true)
    trackEvent({
      trackingData: {
        label: TrackingEventLabel.RenamePopup,
        action: TrackingAction.PopupViewed,
      },
      category: TrackingCategory.Popup,
    })
  }

  return (
    <>
      <RenameConfirmation
        mailingListName={mailingListName}
        onRename={onRenameHandler}
        onCancel={onCancelHandler}
        isOpen={showRenameConfirmation}
        existingMailingListNames={existingMailingListNames}
      />
      <Button
        data-testid='rename-table-button'
        skin='secondary'
        size='mini'
        mr='4'
        px='5'
        data-section='Mailing List Rename Button'
        data-position={(index + 1).toString()}
        data-translation='Button Clicked:Mailing List Rename Button'
        onClick={(e: React.MouseEvent<HTMLElement>) =>
          onRenameConfirmationHandler(e)}
      >
        {renameOnProgress && <Spinner size='tiny' accessibleText={renamingInProgress} />}
        {renameButtonTitle}
      </Button>
    </>
  )
}

export default Rename
