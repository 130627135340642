import React from 'react'
import { SummaryItemAction } from '../models/MailingListSummary'
import Delete from './ActionButtons/Delete'
import Download from './ActionButtons/Download'
import Rename from './ActionButtons/Rename'

export interface ActionClickPayload {
  type: string
  itemId: string
  newName?: string
  currentName: string
}

interface ActionsProps {
  actions: {
    action: SummaryItemAction
    id: string
    caption: string
    mailingListName: string
  }[]
  existingMailingListNames: string[]
  onActionClick(event: ActionClickPayload): Promise<void>
  index: number
}

const ActionButtons: React.FC<ActionsProps> = ({
  actions,
  onActionClick,
  existingMailingListNames,
  index,
}) => {
  const components = actions.map(actionItem => {
    switch (actionItem.action) {
      case SummaryItemAction.delete:
        return (
          <Delete
            key={`delete-${actionItem.id}`}
            id={actionItem.id}
            mailingListName={actionItem.mailingListName}
            onActionClick={onActionClick}
            index={index}
          />
        )
      case SummaryItemAction.download:
        return (
          <Download
            key={`download-${actionItem.id}`}
            id={actionItem.id}
            onActionClick={onActionClick}
            mailingListName={actionItem.mailingListName}
            index={index}
          />
        )
      default:
        return (
          <Rename
            key={`rename-${actionItem.id}`}
            id={actionItem.id}
            onActionClick={onActionClick}
            mailingListName={actionItem.mailingListName}
            existingMailingListNames={existingMailingListNames}
            index={index}
          />
        )
    }
  })

  return <>{components}</>
}

export default ActionButtons
