import React, { ReactNode } from 'react'
import { useScreenClass } from '@vp/swan'

interface Props {
  children: ReactNode
}

export interface ResponsiveContextValue {
  isMobile: boolean
  isSmallScreen: boolean
  isMediumScreen: boolean
  isLargeScreen: boolean
  isExtraLargeScreen: boolean
}

const initialState = {
  isMobile: false,
  isSmallScreen: false,
  isMediumScreen: false,
  isLargeScreen: false,
  isExtraLargeScreen: false,
}

export const ResponsiveContext =
  React.createContext<ResponsiveContextValue>(initialState)

const isBrowser = typeof window !== 'undefined'

const ResponsiveProvider: React.FC<Props> = ({ children }) => {
  const screenClass = useScreenClass()

  const state = {
    isMobile: screenClass && screenClass === 'xs',
    isSmallScreen: screenClass && screenClass === 'sm',
    isMediumScreen: screenClass && screenClass === 'md',
    isLargeScreen: screenClass && screenClass === 'lg',
    isExtraLargeScreen: screenClass && screenClass === 'xl',
  }

  return (
    <ResponsiveContext.Provider value={state}>
      {children}
    </ResponsiveContext.Provider>
  )
}

const useResponsiveState = (): ResponsiveContextValue => {
  const context = React.useContext<ResponsiveContextValue>(ResponsiveContext)
  if (isBrowser && context === undefined) {
    throw new Error(
      'useResponsiveState must be used within a ResponsiveProvider'
    )
  }
  return context
}

export { ResponsiveProvider, useResponsiveState }
