import { Column, GridContainer, Row } from '@vp/swan'
import Alertpopup from '../AlertPopup'
import MailingListSummary from './MailingListSummary'

const MailingListContainer = () => {
  return (
    <>
      <GridContainer mb={12}>
        <Row sticky>
          <Column span={12} sticky>
            <Alertpopup />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <MailingListSummary />
          </Column>
        </Row>
      </GridContainer>
    </>
  )
}

export default MailingListContainer
