import { REQUESTOR } from '../constants'
import { IMailingListStatusSummary } from '../domain/model/MailingListSummary'
import { handleErrors } from '../utils/handleErrors'
import fileDownload from 'js-file-download'

export interface IMailingListSummaryRequestProps {
  shopperId: string | undefined
  authorizationHeader: string
  addressBookId: string
  locale: string
}

export interface IMailingListDeleteRequestProps {
  shopperId: string | undefined
  mailingListId: string
  authorizationHeader: string
}
export interface IMailingListDownloadRequestProps {
  shopperId: string | undefined
  mailingListId: string
  authorizationHeader: string
  locale: string
}

export interface IMailingListRenameRequestProps {
  shopperId: string | undefined
  mailingListId: string
  authorizationHeader: string
  newName: string
}

const SERVICE_URL_PREFIX =
  'https://customer-address-book-api.prod.merch.vpsvc.com'

export const getMailingListSummary = async ({
  shopperId,
  authorizationHeader,
  addressBookId,
  locale,
}: IMailingListSummaryRequestProps): Promise<
  IMailingListStatusSummary | undefined
> => {
  const customerAddressBookAPIUrl = `${SERVICE_URL_PREFIX}/v3/mailing-list/status-summary/${locale}/${shopperId}/${addressBookId}?requestor=${REQUESTOR}`

  const response = await fetch(customerAddressBookAPIUrl, {
    headers: {
      Authorization: authorizationHeader,
    },
  })

  await handleErrors(response)
  const result = (await response.json()) as IMailingListStatusSummary
  return result
}

export const deleteMailingList = async ({
  mailingListId,
  shopperId,
  authorizationHeader,
}: IMailingListDeleteRequestProps): Promise<void> => {
  const addressBookStoreUrl = `${SERVICE_URL_PREFIX}/v3/mailing-list/${shopperId}/${mailingListId}?requestor=${REQUESTOR}`

  const response = await fetch(addressBookStoreUrl, {
    method: 'DELETE',
    headers: {
      Authorization: authorizationHeader,
    },
  })

  await handleErrors(response)
}

export const downloadMailingList = async ({
  mailingListId,
  shopperId,
  authorizationHeader,
  locale,
}: IMailingListDownloadRequestProps): Promise<void> => {
  const addressBookStoreUrl = `${SERVICE_URL_PREFIX}/v3/mailing-list/download/${locale}/${shopperId}/${mailingListId}?requestor=${REQUESTOR}&locale=${locale}&useSimplifiedTemplate=true`

  const response = await fetch(addressBookStoreUrl, {
    headers: {
      Authorization: authorizationHeader,
    },
    method: 'GET',
  })

  await handleErrors(response)
  const blob = await response.blob()
  const filename = response.headers.get('content-disposition')?.split('filename=')[1].split(';')[0] || 'download.csv'
  fileDownload(blob, filename)
}

export const renameMailingList = async ({
  mailingListId,
  shopperId,
  authorizationHeader,
  newName,
}: IMailingListRenameRequestProps): Promise<void> => {
  const addressBookStoreUrl = `${SERVICE_URL_PREFIX}/v3/mailing-list/${shopperId}/${mailingListId}?requestor=${REQUESTOR}`

  const response = await fetch(addressBookStoreUrl, {
    method: 'PATCH',
    headers: {
      Authorization: authorizationHeader,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ name: newName }),
  })

  await handleErrors(response)
}
