import { getTracking } from '@vp/tracking'
import { TrackingEventLabels, TrackingCategory } from '../constants'
import { TrackingData } from '../models/Tracking'

const trackEvent = (params: {
  trackingData: TrackingData
  category: TrackingCategory
}) => {
  const tracking = getTracking()

  if (!tracking) {
    console.info('Tracking is not available')
    return
  }

  const { trackingData, category } = params
  const { eventDetail, label, action } = trackingData
  const payload = {
    category,
    label,
    eventDetail,
    pageName: TrackingEventLabels.TRACKING_PAGE_NAME,
    pageSection: TrackingEventLabels.TRACKING_PAGE_SECTION,
    pageStage: TrackingEventLabels.TRACKING_PAGE_STAGE,
  }

  tracking.track(action, payload)
}

export { trackEvent }
