export interface IMailingListStatusSummaryItem {
  mailingListId: string
  name: string
  numberOfAddresses: number
  createdOn: number
  expiresOn?: number
  actions: SummaryItemAction[]
  status: SummaryItemStatus
  localizedStatus: string
  type: MailingListType
}

export interface IMailingListStatusSummary
  extends Array<IMailingListStatusSummaryItem> {}

export enum SummaryItemAction {
  rename = 'rename',
  download = 'download',
  delete = 'delete',
}

export enum SummaryItemStatus {
  validated = 'validated',
  created = 'created',
  processing = 'processing',
  pendingValidation = 'pendingValidation',
  canceled = 'canceled',
  invalidData = 'invalidData',
}

export enum MailingListType {
  M2M = 'M2M',
  Uploaded = 'Uploaded',
  Purchased = 'Purchased',
  MigratedMerged = 'MigratedMerged',
}
