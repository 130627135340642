import { REQUESTOR } from '../constants'
import { AddressBooks } from '../domain/model/AddressBook'
import { handleErrors } from '../utils/handleErrors'

export interface IAddressBooksRequestProps {
  authorizationHeader: string
  country?: string
}

export const getAddressBooks = async ({
  authorizationHeader,
  country,
}: IAddressBooksRequestProps): Promise<AddressBooks> => {
  const addressBookStoreUrl = `https://address-book-store.prod.ams.vpsvc.com/api/v2/${country}/customers/me/address-books?requestor=${REQUESTOR}`

  const response = await fetch(addressBookStoreUrl, {
    headers: {
      Authorization: authorizationHeader,
    },
  })

  await handleErrors(response)

  const pageDataResult = await response.json() as any
  const result = pageDataResult.addressBooks as AddressBooks
  return result
}
