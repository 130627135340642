import { SummaryItemAction } from '../models/MailingListSummary'

export interface ActionButtonCaptions {
  delete: string
  rename: string
  download: string
}

export const buildCaptionButtonFromAction = (
  action: SummaryItemAction,
  captions: ActionButtonCaptions
): string => {
  let translation = ''
  switch (action) {
    case SummaryItemAction.rename:
      translation = captions.rename
      break
    case SummaryItemAction.delete:
      translation = captions.delete
      break
    case SummaryItemAction.download:
    default:
      translation = captions.download
      break
  }

  return translation
}
