export const utlToLocalString = (
  utcTimestamp: number,
  locale: string
): string => {
  const date = new Date(utcTimestamp)

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }

  try {
    return date.toLocaleDateString(locale, options)
  } catch (error) {
    return 'Invalid Date'
  }
}
