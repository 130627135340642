import { FlexBox, Button, H4 } from '@vp/swan'
import { useLocalization } from '../../context/useLocalizations'
import { useIdentity } from '@vp/ubik-context'

const SignInMessage = () => {
  const { t } = useLocalization()
  const signInTranslation = t('signIn')
  const signInCTATranslation = t('signInCTA')
  const { auth } = useIdentity()

  const signIn = () => {
    auth?.signIn()
  }

  return (
    <FlexBox
      className='mailing-list-table-placeholder'
      justifyContent='center'
      alignItems='center'
      flexDirection='column'
    >
      <H4 className='css-fix' my='7' textAlign='center'>
        {signInTranslation}
      </H4>
      <Button onClick={signIn} skin='primary'>
        {signInCTATranslation}
      </Button>
    </FlexBox>
  )
}

export default SignInMessage
