import {
  StandardTile,
  StandardTileName,
  StandardTileDescription,
  Typography,
  Row,
  Column,
  GridContainer,
  Divider,
} from '@vp/swan'
import React from 'react'
import { useUserContext } from '@vp/ubik-context'
import { useLocalization } from '../../context/useLocalizations'
import useServiceClients from '../../hooks/useServiceClients'
import {
  IMailingListStatusSummary,
  SummaryItemAction,
} from '../../models/MailingListSummary'
import { buildCaptionButtonFromAction } from '../../title/buildButtonTitle'
import { buildSummaryDateDataTitle } from '../../title/buildSummaryDateDataTitle'
import ActionButtons, { ActionClickPayload } from '../ActionButtons'

interface MailingListTilesProps {
  data: IMailingListStatusSummary
  onDataChange(newData: IMailingListStatusSummary): void
}

const MailingListTiles: React.FC<MailingListTilesProps> = ({
  data,
  onDataChange,
}) => {
  const { t } = useLocalization()
  const listTitle = t('listTitle')
  const status = t('status')
  const entries = t('entries')
  const purchased = t('purchased')
  const uploaded = t('uploaded')
  const archive = t('archive')
  const deleteTitle = t('deleteTitle')
  const migrated = t('migrated')
  const downloadButtonTitle = t('downloadButtonTitle')
  const renameButtonTitle = t('renameButtonTitle')
  const deleteButtonTitle = t('deleteButtonTitle')

  const {
    deleteMailingListItem,
    downloadMailingListItem,
    renameMailingListItem,
  } = useServiceClients()
  const { locale } = useUserContext()

  const onActionClickHandler = async (payload: ActionClickPayload) => {
    switch (payload.type) {
      case SummaryItemAction.delete:
        await deleteMailingListItem(payload.itemId, payload.currentName)
        onDataChange(data.filter(item => item.mailingListId !== payload.itemId))
        break
      case SummaryItemAction.download:
        await downloadMailingListItem(payload.itemId, payload.currentName)
        break
      case SummaryItemAction.rename: {
        await renameMailingListItem({
          itemId: payload.itemId,
          newName: payload.newName as string,
          currentMailingListName: payload.currentName,
        })
        const renamedItem = data.find(
          item => item.mailingListId === payload.itemId
        )
        if (renamedItem != null) {
          renamedItem.name = payload.newName || ''
          onDataChange([...data])
        }

        break
      }
      default:
        break
    }
  }

  const actionButtonCaptions = {
    delete: deleteButtonTitle,
    rename: renameButtonTitle,
    download: downloadButtonTitle,
  }

  const existingMailingListNames = data.map(mlItem => mlItem.name)

  return (
    <GridContainer component='ul'>
      {data.map((mailingListSummaryItem, index) => {
        const titleProps = {
          translations: { purchased, uploaded, archive, deleteTitle, migrated },
          locale,
          type: mailingListSummaryItem.type,
          createdOn: mailingListSummaryItem.createdOn,
          expiresOn: mailingListSummaryItem.expiresOn,
        }

        const dateData = buildSummaryDateDataTitle(titleProps)
        return (
          <Row
            component='li'
            key={mailingListSummaryItem.mailingListId}
            mb='6'
          >
            <Column span={12}>
              <Divider my='7' />
              <StandardTile
                data-testid={`mailing-list-item-${index}`}
                skin='standard'
                imageWidth='standard'
                mx='7'
                mb={0}
              >
                <StandardTileName>
                  <Typography
                    fontSkin='body-standard-bold'
                    style={{ overflowWrap: 'break-word' }}
                  >
                    {listTitle}: {mailingListSummaryItem.name}
                  </Typography>
                </StandardTileName>
                <StandardTileDescription>
                  <Typography
                    data-testid={`mailing-list-item-mobile-date-${index}`}
                    fontSkin='body-small'
                    className='css-fix title-date'
                    textColor='subtle'
                  >
                    {dateData}
                  </Typography>
                </StandardTileDescription>
                <StandardTileDescription>
                  <Typography fontSkin='body-small'>
                    {' '}
                    {status}: {mailingListSummaryItem.localizedStatus}
                  </Typography>
                </StandardTileDescription>
                <StandardTileDescription>
                  <Typography fontSkin='body-small'>
                    {entries}: {mailingListSummaryItem.numberOfAddresses}
                  </Typography>
                </StandardTileDescription>
                <ActionButtons
                  index={index}
                  onActionClick={onActionClickHandler}
                  existingMailingListNames={existingMailingListNames}
                  actions={mailingListSummaryItem.actions.map(action => {
                    return {
                      action,
                      mailingListName: mailingListSummaryItem.name,
                      id: `${mailingListSummaryItem.mailingListId}`,
                      caption: buildCaptionButtonFromAction(
                        action,
                        actionButtonCaptions
                      ),
                    }
                  })}
                />
              </StandardTile>
            </Column>
          </Row>
        )
      })}
      {data.length > 0 && <Divider mt='7' />}
    </GridContainer>
  )
}

export default MailingListTiles
