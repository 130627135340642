import React, { createContext, ReactNode, useContext, useState } from 'react'

interface Props {
  children: ReactNode
}

interface AlertSettings {
  skin: 'standard' | 'warning' | 'error' | 'positive' | 'legal-warning'
  message: string
}

const initialAlertSettings: AlertSettings = {
  skin: 'standard',
  message: '',
}

interface IAlertPopupContext {
  showWarning(message: string): void
  showError(message: string): void
  showMessage(message: string): void
  setDismissed(state: boolean): void
  alertSettings: AlertSettings
  dismissed: boolean
}

const initialContext: IAlertPopupContext = {

  showWarning: (_message: string) => {},

  showError: (_message: string) => {},

  showMessage: (_message: string) => {},

  setDismissed: (_state: boolean) => {},
  alertSettings: initialAlertSettings,
  dismissed: true,
}

const AlertPopupContext = createContext<IAlertPopupContext>(initialContext)

export const AlertPopupProvider: React.FC<Props> = ({ children }) => {
  const [dismissed, setDismissed] = useState(true)
  const [alertSettings, setAlertSettings] = useState(initialAlertSettings)

  const showWarning = (message: string): void => {
    setAlertSettings((prevAlertSettings: AlertSettings) => {
      return { ...prevAlertSettings, skin: 'warning', message }
    })
    setDismissed(false)
  }

  const showError = (message: string): void => {
    setAlertSettings((prevAlertSettings: AlertSettings) => {
      return { ...prevAlertSettings, skin: 'error', message }
    })
    setDismissed(false)
  }

  const showMessage = (message: string): void => {
    setAlertSettings((prevAlertSettings: AlertSettings) => {
      return { ...prevAlertSettings, skin: 'positive', message }
    })
    setDismissed(false)
  }

  const contextValue = {
    showWarning,
    showError,
    showMessage,
    setDismissed,
    dismissed,
    alertSettings,
  }

  return (
    <AlertPopupContext.Provider value={contextValue}>
      {children}
    </AlertPopupContext.Provider>
  )
}

export const useAlertPopup = (): IAlertPopupContext => {
  const contextValue = useContext(AlertPopupContext)

  if (contextValue === undefined) {
    throw new Error('useAlertPopup must be used within a AlertPopupProvider')
  }

  return contextValue
}
