import React, { useState } from 'react'
import { Spinner, Button } from '@vp/swan'

import { ActionClickPayload } from '../ActionButtons'
import { SummaryItemAction } from '../../models/MailingListSummary'
import DeleteConfirmation from '../Modals/DeleteConfirmation'
import { trackEvent } from '../../Analytics/tracking'
import {
  TrackingEventLabel,
  TrackingAction,
  TrackingCategory,
} from '../../constants'
import { useLocalization } from '../../context/useLocalizations'

interface ActionsProps {
  id: string
  mailingListName: string
  index: number
  onActionClick(event: ActionClickPayload): Promise<void>
}

const Delete: React.FC<ActionsProps> = ({
  id,
  mailingListName,
  onActionClick,
  index,
}) => {
  const [deleteOnProgress, setDeleteOnProgress] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const { t } = useLocalization()

  const deleteButtonTitle = t('deleteButtonTitle')
  const deletingInProgress = t('deletingInProgress')

  const onDeleteHandler = async (performDeletion: boolean): Promise<void> => {
    setShowDeleteConfirmation(false)
    if (performDeletion === true) {
      setDeleteOnProgress(true)
      try {
        await onActionClick({
          type: SummaryItemAction.delete,
          itemId: id,
          currentName: mailingListName,
        })
      } catch {
        // Nothing - we need to avoid blowing up in order to set the delete process to false
      }

      setDeleteOnProgress(false)
    } else {
      trackEvent({
        trackingData: {
          label: TrackingEventLabel.DeletePopup,
          action: TrackingAction.PopupClosed,
        },
        category: TrackingCategory.Popup,
      })
    }
  }

  const onCancelHandler = () => {
    setShowDeleteConfirmation(false)
  }

  const onDeleteConfirmationHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    setShowDeleteConfirmation(true)
    trackEvent({
      trackingData: {
        label: TrackingEventLabel.DeletePopup,
        action: TrackingAction.PopupViewed,
      },
      category: TrackingCategory.Popup,
    })
  }

  return (
    <>
      <DeleteConfirmation
        mailingListName={mailingListName}
        onDelete={onDeleteHandler}
        onCancel={onCancelHandler}
        isOpen={showDeleteConfirmation}
      />
      <Button
        skin='secondary'
        size='mini'
        mr='4'
        px='5'
        data-section='Mailing List Delete Button'
        data-position={(index + 1).toString()}
        data-translation='Button Clicked:Mailing List Delete Button'
        onClick={(e: React.MouseEvent<HTMLElement>) =>
          onDeleteConfirmationHandler(e)}
      >
        {deleteOnProgress && <Spinner size='tiny' accessibleText={deletingInProgress} />}
        {deleteButtonTitle}
      </Button>
    </>
  )
}

export default Delete
