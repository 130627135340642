import React from 'react'
import {
  Button,
  ModalDialog,
  ModalDialogBody,
  ModalDialogButtons,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogFooter,
  ModalDialogHeader,
  ModalDialogNav,
  ModalDialogTitle,
} from '@vp/swan'

import { useLocalization } from '../../context/useLocalizations'

interface ActionsProps {
  mailingListName: string
  isOpen: boolean
  onDelete(performDeletion: boolean): Promise<void>
  onCancel(): void
}

const DeleteConfirmation: React.FC<ActionsProps> = ({
  mailingListName,
  onDelete,
  onCancel,
  isOpen,
}) => {
  const { t } = useLocalization()

  const {
    deleteFileCTA,
    deleteFileDescription,
    cancelButtonCaption,
    closeButtonCaption,
    deleteDialogContent,
  } = {
    deleteFileCTA: t('deleteFileCTA'),
    deleteFileDescription: t('deleteFileDescription'),
    cancelButtonCaption: t('cancelButtonCaption'),
    closeButtonCaption: t('closeButtonCaption'),
    deleteDialogContent: t('deleteDialogContent'),
  }

  const onDeleteConfirmationClickHandler = async (performDeletion: boolean) => {
    await onDelete(performDeletion)
  }

  const deleteDialogTitle = t('deleteFile', { mailingListName })

  return (
    <ModalDialog
      isOpen={isOpen}
      onRequestDismiss={() => onDeleteConfirmationClickHandler(false)}
    >
      <ModalDialogContent aria-labelledby={deleteDialogContent}>
        <ModalDialogNav>
          <ModalDialogCloseButton
            visuallyHiddenLabel={closeButtonCaption}
            data-section='Mailing List Delete Pop-Up'
            data-position='0'
            data-translation='Pop-Up Closed'
          />
        </ModalDialogNav>
        <ModalDialogHeader style={{ maxWidth: 'fit-content' }}>
          <ModalDialogTitle data-testid='delete-file-text'>
            {deleteDialogTitle}
          </ModalDialogTitle>
        </ModalDialogHeader>
        <ModalDialogBody
          data-testid='delete-warning-text'
          style={{ textAlign: 'left' }}
        >
          {deleteFileDescription}
        </ModalDialogBody>
        <ModalDialogFooter>
          <ModalDialogButtons>
            <Button
              skin='primary'
              data-testid='delete-confirmation-button'
              data-section='Mailing List Delete Pop-Up'
              data-position='1'
              data-translation='Pop-Up Clicked:Mailing List Delete Pop-Up'
              onClick={() => onDeleteConfirmationClickHandler(true)}
            >
              {deleteFileCTA}
            </Button>
            <Button ml='between-actions' skin='secondary' onClick={onCancel}>
              {cancelButtonCaption}
            </Button>
          </ModalDialogButtons>
        </ModalDialogFooter>
      </ModalDialogContent>
    </ModalDialog>
  )
}

export default DeleteConfirmation
