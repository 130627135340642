import { utlToLocalString } from '../dates/utcToLocalString'
import { MailingListType } from '../models/MailingListSummary'

export interface BuildSummaryTitleProps {
  type: MailingListType
  createdOn: number
  expiresOn?: number
  translations: { [key: string]: string }
  locale: string
}

export interface TitleData {
  name: string
  dateData: string
}

export const buildSummaryDateDataTitle = ({
  type,
  createdOn,
  translations,
  locale,
  expiresOn,
}: BuildSummaryTitleProps): string => {
  if (!createdOn) {
    return ''
  }

  const localizedCreatedDate = utlToLocalString(createdOn, locale)
  const { purchased, migrated, uploaded, archive, deleteTitle } = translations
  let dateData = ''

  switch (type) {
    case MailingListType.Purchased:
      dateData = `${purchased} ${localizedCreatedDate}`
      if (expiresOn != null) {
        dateData += `\n${archive} ${utlToLocalString(expiresOn, locale)}`
      }
      break
    case MailingListType.M2M:
    case MailingListType.Uploaded:
      dateData = `${uploaded} ${localizedCreatedDate}`
      if (expiresOn != null) {
        dateData += `\n${deleteTitle} ${utlToLocalString(expiresOn, locale)}`
      }
      break
    case MailingListType.MigratedMerged:
      dateData = `${migrated} ${localizedCreatedDate}`
  }

  return dateData
}
