export const REQUESTOR = 'my-account-address-list'

export enum TrackingEventLabels {
  MAILING_SERVICES_POSTCARD_MPV_ID = 'mailingServicesPostcard',
  TRACKING_PAGE_NAME = 'My Account:Mailing List',
  TRACKING_PAGE_SECTION = 'My Account',
  TRACKING_PAGE_STAGE = 'My Account'
}

export enum TrackingEventLabel {
  DeletePopup = 'Mailing List Delete Pop-Up',
  RenamePopup = 'Mailing List Rename Pop-Up',
  Download = 'Download',
}

export enum TrackingAction {
  PopupViewed = 'Pop-Up Viewed',
  PopupClosed = 'Pop-Up Closed',
  PopupClicked = 'Pop-Up Clicked',
  DownloadClicked = 'Download clicked',
}

export enum TrackingCategory {
  Popup = 'Pop-Up',
  Page = 'My Account-Mailing Lists',
}

export const MAILING_SERVICES_POSTCARD_MPV_ID = 'mailingServicesPostcard'
