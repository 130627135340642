import { Card, FlexBox, H4 } from '@vp/swan'
import { useLocalization } from '../../context/useLocalizations'

import MailingServicesPostcardCTA from '../MailingServicesPostcardCTA'

const NoMailingLists = () => {
  const { t } = useLocalization()
  const noMailingListMessage = t('noMailingListMessage')

  return (
    <Card bordered backgroundColor='strong' my='5' paddingBottom={8}>
      <FlexBox
        className='mailing-list-table-placeholder'
        justifyContent='center'
        alignItems='center'
        flexDirection='column'
      >
        <H4 className='css-fix' my='7' textAlign='center'>
          {noMailingListMessage}
        </H4>
        <MailingServicesPostcardCTA />
      </FlexBox>
    </Card>
  )
}

export default NoMailingLists
