import { Typography } from '@vp/swan'
import React from 'react'

interface MailingListsTitleCellProps {
  name: string
  dateData: string
  index: number
}

const MailingListTitleCell: React.FC<MailingListsTitleCellProps> = ({
  name,
  index,
  dateData,
}) => {
  return (
    <>
      <Typography fontSkin='body-small-bold'>{name}</Typography>
      <Typography
        data-testid={`mailing-list-item-date-${index}`}
        fontSkin='body-small'
        className='css-fix title-date'
        textColor='subtle'
      >
        {dateData}
      </Typography>
    </>
  )
}

export default MailingListTitleCell
