import * as React from 'react'
import { AlertBox, AlertBoxDismissButton, Typography } from '@vp/swan'
import { useAlertPopup } from '../context/useAlertPopup'

const Alertpopup: React.FC = () => {
  const { dismissed, alertSettings, setDismissed } = useAlertPopup()
  const { message, skin } = alertSettings

  return (
    <AlertBox
      toast
      my={6}
      skin={skin}
      dismissed={dismissed}
      onRequestDismiss={() => {
        setDismissed(true)
      }}
    >
      <Typography textAlign='center'>{message}</Typography>
      <AlertBoxDismissButton visuallyHiddenLabel='Dismiss alert' />
    </AlertBox>
  )
}

export default Alertpopup
